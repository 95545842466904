<template>
  <div>
    <c-tab
      ref="hazopActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :hcfFacilityType.sync="tab.hcfFacilityType"
          :popupParam.sync="popupParam"
          :facilityStatusTable="facilityStatusTable"
          :contentHeight="contentHeight"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar';
export default {
  name: 'facility-status-table-facility',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',
      }),
    },
    facilityStatusTable: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',  // 취급시설 현황표 일련번호
        facilityStatusTableName: '',  // 현황표명
        plantCd: '',  // 사업장코드
        permitDate: '',  // 허가일
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '',  // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        attrVal1: '',  // 회사id
        attrVal2: '',  // 소재지
        attrVal3: '',  // 시설구분
        regDtStr: '',
        regUserName: '',
        managers: [], // 유해화학물질관리자
        facilities: [], // 시설
        hazardChems: [], // 유해화학물질 취급현황
        equipDevices: [], // 설비 장치
        equipPowers: [], // 설비 동력기계
      }),
    },
    hcfFacilityTypeItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: '',
      editable: true,
    };
  },
  computed: {
    tabItems() {
      let _items = [];
      if (this.hcfFacilityTypeItems && this.hcfFacilityTypeItems.length > 0) {
        this.$_.forEach(this.hcfFacilityTypeItems, item => {
          _items.push({
            key: uid(), name: item.code, label: item.codeName, 
            hcfFacilityType: { hcfFacilityTypeCd: item.code, hcfFacilityTypeName: item.codeName },
            component: () => import(`${'./facilityStatusTableFacilityInfo.vue'}`)
          })
        })
      }
      return _items;
    },
    tabHeight() {
      return (this.contentHeight ? this.contentHeight - 80 : 0) + 'px'
    }
  },
  watch: {
    hcfFacilityTypeItems: {
      handler: function () {
        if (this.hcfFacilityTypeItems && this.hcfFacilityTypeItems.length > 0) {
          this.tab = this.hcfFacilityTypeItems[0].code
        }
      },
      deep: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      if (this.hcfFacilityTypeItems && this.hcfFacilityTypeItems.length > 0) {
        this.tab = this.hcfFacilityTypeItems[0].code
      }
    },
  }
};
</script>
